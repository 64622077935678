import { createSlice } from '@reduxjs/toolkit';
import { NewOnboardingState } from 'lib/types';

const initialState: NewOnboardingState = {
  onboardingModalVisible: false,
};

const name = 'newOnboarding';

const newOnboardingSlice = createSlice({
  name,
  initialState,
  reducers: {
    toggleOnboardingModal: (state, action) => {
      state.onboardingModalVisible = action.payload;
    },
  },
});

export const { toggleOnboardingModal } = newOnboardingSlice.actions;
export default newOnboardingSlice.reducer;
