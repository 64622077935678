import { INotificationMode } from 'lib/bbcommon/types';
import { IFeedbackForm } from 'lib/settings/feedback-form';
import { BridebookError } from 'lib/types';

export interface IChangeCorrespondenceEmailForm {
  disabled: boolean;
  error: BridebookError | null;
  fields: { email: string };
}

export interface SettingsState {
  linkAccountsError: Error | null;
  changeCorrespondenceEmailForm: IChangeCorrespondenceEmailForm;
  resetPasswordSuccess: boolean;
  resetPasswordStart: boolean;
  showFeedbackPopup: boolean;
  feedbackForm: IFeedbackForm;
  userDeletionStatus: UserDeletionStatus;
  showLocationChangeModal: boolean;
  changeWeddingCountryStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
}

export interface INotification {
  type: INotificationMode;
  message: string;
}

export enum UserDeletionStatus {
  Disabled,
  Success,
  Pending,
  Error,
}
