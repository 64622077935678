import { getParticipatedInABTestPayload } from 'app-shared/lib/ab-tests/participated-in-test-analytics';
import { Action, IApplicationState } from 'lib/types';
import { IParticipatedInABTestAnalyticsAction, UIActionTypes } from 'lib/ui/action-types';
import { toUrlQuery } from 'lib/utils';
import { AuthActionTypes, UserSetupCompletedAction } from '../auth/action-types';
import { WebAnalyticsContext } from '../bbcommon/utils/bridebook-analytics';

declare var window: Window & typeof globalThis & { plugins: any };

const appAnalytics = (
  action: Action,
  bridebookAnalytics: WebAnalyticsContext,
  getState: () => IApplicationState,
): void => {
  const { track, identify } = bridebookAnalytics.getMethods('All');

  switch (action.type) {
    case UIActionTypes.PARTICIPATED_IN_ABTEST_ANALYTICS: {
      // A/B testing main analytics event for logged-in
      const { abTest, variant } = action.payload as IParticipatedInABTestAnalyticsAction['payload'];
      track(getParticipatedInABTestPayload({ abTest, variant }));

      break;
    }

    case 'ROUTE_CHANGE_COMPLETE': {
      const { host, isCordova } = getState().app.device;
      const { url, query, previousPath } = action.payload;
      const pathname = url.split('?')[0];
      const prevPathname = previousPath ? previousPath.split('?')[0] : '';

      if (isCordova && window && window.plugins && window.plugins.crashlyticsPlugin) {
        window.plugins.crashlyticsPlugin.setStringValueForKey(pathname, 'pathname');
      }

      if (pathname !== prevPathname && !query.searchPopup && !query.enquiryConfirmation) {
        bridebookAnalytics.page(pathname, {
          path: pathname,
          title: pathname,
          referrer: toUrlQuery(query),
          url: `https://${host}${url}`,
        });
      }
      break;
    }

    case AuthActionTypes.USER_SETUP_COMPLETED: {
      const {
        payload: { user },
      } = action as UserSetupCompletedAction;
      identify(user.id, {});
    }

    case 'APP_STARTED': {
      const { id } = getState().users.user || {};

      if (id) {
        identify(id, {});
      }

      break;
    }

    default:
      break;
  }
};
export default appAnalytics;
