import produce from 'immer';
import { pathOr } from 'ramda';
import { IWedding } from '@bridebook/models/source/models/Weddings.types';
import { EnquiryIntentCheckboxes, mapDateToUI, mergeClean } from '@bridebook/toolbox/src';
import { AuthActionTypes, UserSetupCompletedAction } from 'lib/auth/action-types';
import {
  EnquiriesActionTypes,
  IChangeEnquiryContextAction,
  ICheckEnquiryDetailAction,
  IConfirmEnquiryFormErrorAction,
  IEnquiryEditToggleDatepickerAction,
  IEnquiryFormSetEditState,
  IEnquiryFormToggleAction,
  IEnquiryFormToggleEditAction,
  IEnquiryRequiredDataToggleAction,
  IEnquirySetInfoProps,
  IFetchEnquiryConfirmationSupplierSuccessAction,
  IFetchEnquiryCountSuccess,
  IFetchEnquiryDatesSuccess,
  IResetEnquiryFormAction,
  ISaveEnquiryFieldsToDatabaseErrorAction,
  ISetEnquiryFormFieldAction,
  ISetEnquirySupplierAction,
  ISetTriedToContactAction,
  IShowEnquiriesUserLimitModalAction,
  IToggleEnquiryConfirmationScreenAction,
  IToggleEnquiryMessageAction,
  IToggleMultipleEnquiry,
  IValidateEnquiryFormErrorAction,
} from 'lib/enquiries/action-types';
import { Action, IEnquiriesState, IReducersImmer } from 'lib/types';
import { IOnUserListenerAction, UserActionTypes } from 'lib/users/action-types';
import { getReducerActions } from 'lib/utils';
import { IUpdateWeddingAction, WeddingActionTypes } from 'lib/weddings/action-types';
import { getEnquiryForm } from './enquiry-form';
import { EnquiryFormFieldsType } from './types';

const getInitialState = (): IEnquiriesState => ({
  showEnquiryForm: false,
  showEnquiryRequiredData: false,
  supplierContacted: false,
  supplierTriedToContact: false,
  sendEnquiryFailed: false,
  enquiryFormBusy: false,
  enquiryForm: getEnquiryForm(),
  enquirySupplier: null,
  userEnquiryAnalyticsMethod: '',
  userEnquiryAnalyticsActionLabel: '',
  userEnquiryAnalyticsLocationType: '',
  enquiryFormEditState: false,
  enquiryFormDatepickerState: false,
  intent: { ...EnquiryIntentCheckboxes },
  enquiryMessageVisible: false,
  enquiryConfirmationScreenShown: false,
  multipleEnquiry: false,
  enquiredSuppliersCount: 0,
  enquiryDates: {},
  enquiryCount: -1,
  enquiryConfirmationSupplier: null,
  showUserLimit: false,
  loggedOutEnquiryTriggered: false,
  infoProps: {
    method: '',
    locationType: '',
    contactSection: '',
  },
});

const reducers: IReducersImmer<IEnquiriesState> = (draft) => ({
  [EnquiriesActionTypes.SEND_ENQUIRY_START]: () => {
    draft.enquiryFormBusy = true;
  },

  [EnquiriesActionTypes.SEND_ENQUIRY_ERROR]: () => {
    draft.enquiryFormBusy = false;
    draft.sendEnquiryFailed = true;
    draft.supplierContacted = false;
    draft.supplierTriedToContact = false;
  },

  [EnquiriesActionTypes.SEND_ENQUIRY_SUCCESS]: () => {
    draft.enquiryForm.error = null;
    draft.enquiryFormBusy = false;
    draft.sendEnquiryFailed = false;
  },

  [EnquiriesActionTypes.TOGGLE_ENQUIRY_CONFIRMATION_SCREEN]: (
    action: IToggleEnquiryConfirmationScreenAction,
  ) => {
    draft.enquiryConfirmationScreenShown = action.payload;
    if (!action.payload) {
      draft.multipleEnquiry = false;
    }
  },

  [EnquiriesActionTypes.TOGGLE_ENQUIRY_CONFIRMATION]: (
    action: IToggleEnquiryConfirmationScreenAction,
  ) => {
    if (!action.payload) {
      draft.multipleEnquiry = false;
    }
  },

  [EnquiriesActionTypes.TOGGLE_MULTIPLE_ENQUIRY]: (action: IToggleMultipleEnquiry) => {
    draft.multipleEnquiry = action.payload;
  },

  [EnquiriesActionTypes.SET_ENQUIRY_FORM_FIELD]: (action: ISetEnquiryFormFieldAction) => {
    const { name, value } = action.payload;
    const isDirty = name === 'message';

    if (name === 'partnerName1' || name === 'partnerName2') {
      if (name === 'partnerName1') {
        // @ts-expect-error
        draft.enquiryForm.fields.partners[0] = value;
      }

      if (name === 'partnerName2') {
        // @ts-expect-error
        draft.enquiryForm.fields.partners[1] = value;
      }
    } else if (name === 'guestsInitialTarget') {
      draft.enquiryForm.fields[name] = Number(value);
    } else {
      // @ts-expect-error
      draft.enquiryForm.fields[name] = value;
    }

    draft.enquiryForm.dirty = isDirty;
  },

  [EnquiriesActionTypes.SET_LOGGED_OUT_ENQUIRY_TRIGGERED]: () => {
    draft.loggedOutEnquiryTriggered = true;
  },

  [EnquiriesActionTypes.SET_TRIED_TO_CONTACT]: (action: ISetTriedToContactAction) => {
    draft.supplierTriedToContact = action.payload;
  },

  [EnquiriesActionTypes.CHECK_ENQUIRY_DETAIL]: (action: ICheckEnquiryDetailAction) => {
    const { id, checked } = action.payload;
    draft.intent[id] = checked;
    draft.enquiryForm.error = null;
  },

  [EnquiriesActionTypes.TOGGLE_ENQUIRY_MESSAGE]: (action: IToggleEnquiryMessageAction) => {
    draft.enquiryMessageVisible = action.payload;
  },

  [EnquiriesActionTypes.VALIDATE_ENQUIRY_FORM_ERROR]: (action: IValidateEnquiryFormErrorAction) => {
    draft.enquiryForm.error = action.payload;
    draft.enquiryFormBusy = false;
  },

  [EnquiriesActionTypes.SAVE_ENQUIRY_FIELDS_TO_DATABASE_ERROR]: (
    action: ISaveEnquiryFieldsToDatabaseErrorAction,
  ) => {
    draft.enquiryForm.error = action.payload;
  },

  [EnquiriesActionTypes.CONFIRM_ENQUIRY_FORM_ERROR]: (action: IConfirmEnquiryFormErrorAction) => {
    draft.enquiryForm.error = action.payload;
  },

  [EnquiriesActionTypes.DISMISS_ENQUIRY_FORM_ERROR]: () => {
    draft.enquiryForm.error = null;
  },

  [EnquiriesActionTypes.SET_ENQUIRY_SUPPLIER]: (action: ISetEnquirySupplierAction) => {
    draft.enquirySupplier = action.payload;
  },

  [EnquiriesActionTypes.CHANGE_ENQUIRY_CONTEXT]: (action: IChangeEnquiryContextAction) => {
    const { context, contextMessage, isDirty } = action.payload;

    draft.enquiryForm.fields.message = contextMessage;
    draft.enquiryForm.dirty = isDirty;
    draft.enquiryForm.context = context;
  },

  [EnquiriesActionTypes.ENQUIRY_FORM_TOGGLE]: (action: IEnquiryFormToggleAction) => {
    const { show, infoProps } = action.payload;
    const { method, actionLabel, locationType, contactGalleryCTA } = infoProps;
    const userEnquiryAnalyticsMethod = (show && method) || '';
    const userEnquiryAnalyticsActionLabel = (show && actionLabel) || '';
    const userEnquiryAnalyticsLocationType = show ? locationType || 'main' : '';

    // Update default intent checked state
    if (infoProps.defaultIntent) {
      draft.intent = {
        ...EnquiryIntentCheckboxes,
        brochure: false,
        [infoProps.defaultIntent]: true,
      };
    }

    draft.userEnquiryAnalyticsMethod = userEnquiryAnalyticsMethod;
    draft.userEnquiryAnalyticsActionLabel = userEnquiryAnalyticsActionLabel;
    draft.userEnquiryAnalyticsLocationType = userEnquiryAnalyticsLocationType;
    draft.userEnquiryAnalyticsContactGalleryCTA = contactGalleryCTA;
    draft.showEnquiryForm = show;

    if (!show) {
      draft.infoProps = getInitialState().infoProps;
    }
  },

  [EnquiriesActionTypes.ENQUIRY_REQUIRED_DATA_TOGGLE]: (
    action: IEnquiryRequiredDataToggleAction,
  ) => {
    const { show } = action.payload;
    draft.showEnquiryRequiredData = show;
  },

  [EnquiriesActionTypes.RESET_ENQUIRY_FORM]: (action: IResetEnquiryFormAction) => {
    const { profile, user } = action.payload;
    const {
      enquiryForm: {
        fields: { message },
      },
    } = draft;

    const EnquiryForm = getEnquiryForm();

    let fields: EnquiryFormFieldsType = EnquiryForm.fields;
    // clear all fields except message which should persist per session
    if (profile && user) {
      const location: IWedding['location'] = profile.location || { name: '' };
      fields = mergeClean(EnquiryForm.fields, {
        partners: profile.partners || [],
        location,
        message,
        email: pathOr('', ['contacts', 'email'], user),
        phone: pathOr('', ['contacts', 'phone'], user),
        weddingDate: profile.date,
        weddingDateDatePicker: mapDateToUI(profile.date),
        guestsInitialTarget: profile.guests.estimate || 0,
      });
    } else {
      fields = mergeClean(EnquiryForm.fields, {
        message,
        email: pathOr('', ['contacts', 'email'], user),
        phone: pathOr('', ['contacts', 'phone'], user),
      });
    }

    draft.intent = { ...EnquiryIntentCheckboxes };
    draft.enquiryForm.fields = fields;
    draft.supplierContacted = false;
    draft.supplierTriedToContact = false;
    draft.sendEnquiryFailed = false;
    draft.enquiryFormBusy = false;
  },

  [EnquiriesActionTypes.FETCH_ENQUIRY_CONFIRMATION_SUPPLIER_SUCCESS]: (
    action: IFetchEnquiryConfirmationSupplierSuccessAction,
  ) => {
    const { enquiryConfirmationSupplier, enquiredSuppliersCount } = action.payload;

    draft.enquiryConfirmationSupplier = enquiryConfirmationSupplier;
    draft.enquiredSuppliersCount = enquiredSuppliersCount;
    draft.multipleEnquiry = true;
  },

  [UserActionTypes.ON_USER_LISTENER]: (action: IOnUserListenerAction) => {
    const { payload } = action;

    if (payload) {
      const email = pathOr('', ['contacts', 'email'], payload);
      const phone = pathOr('', ['contacts', 'phone'], payload);

      if (email) {
        draft.enquiryForm.fields.email = email;
        draft.enquiryForm.fields.phone = phone;
      }
    }
  },

  [WeddingActionTypes.UPDATE_WEDDING]: (action: IUpdateWeddingAction) => {
    const { wedding } = action.payload;
    const { enquiryFormEditState } = draft;

    /* Don't update enquiry form if enquiry form edit is still opened */
    if (!action.payload.omitUpdateEnquiryForm && !enquiryFormEditState) {
      const {
        partners,
        date,
        guests: { estimate },
        location = { name: '' },
      } = wedding;

      draft.enquiryForm.fields.partners = partners || [];
      draft.enquiryForm.fields.weddingDate = date;
      draft.enquiryForm.fields.weddingDateDatePicker = mapDateToUI(date);
      draft.enquiryForm.fields.guestsInitialTarget = estimate || 0;
      draft.enquiryForm.fields.location = location;
    }
  },

  [AuthActionTypes.USER_SETUP_COMPLETED]: (action: UserSetupCompletedAction) => {
    const { user } = action.payload;
    draft.enquiryForm.fields.email = user.contacts?.email || '';
  },

  [EnquiriesActionTypes.ENQUIRY_FORM_TOGGLE_EDIT]: (action: IEnquiryFormToggleEditAction) => {
    draft.enquiryFormEditState = action.payload;
  },

  [EnquiriesActionTypes.CONFIRM_ENQUIRY_FORM_SUCCESS]: () => {
    draft.enquiryConfirmationScreenShown = false;
  },

  [EnquiriesActionTypes.ENQUIRY_FORM_TOGGLE_DATEPICKER]: (
    action: IEnquiryEditToggleDatepickerAction,
  ) => {
    draft.enquiryFormDatepickerState = action.payload;
  },

  [WeddingActionTypes.SAVE_WEDDING_DATE_SUCCESS]: () => {
    draft.enquiryFormDatepickerState = false;
  },

  [EnquiriesActionTypes.FETCH_ENQUIRY_DATES_SUCCESS]: (action: IFetchEnquiryDatesSuccess) => {
    draft.enquiryDates = action.payload || {};
  },

  [EnquiriesActionTypes.FETCH_ENQUIRY_COUNT_SUCCESS]: (action: IFetchEnquiryCountSuccess) => {
    draft.enquiryCount = action.payload;
  },

  [EnquiriesActionTypes.CLEAR_ENQUIRY_COUNT]: () => {
    draft.enquiryCount = -1;
  },

  [EnquiriesActionTypes.SHOW_ENQUIRIES_USER_LIMIT_MODAL]: (
    action: IShowEnquiriesUserLimitModalAction,
  ) => {
    const { show } = action.payload;
    draft.showUserLimit = show;
  },
  [EnquiriesActionTypes.ENQUIRY_FORM_SET_EDIT_STATE]: (action: IEnquiryFormSetEditState) => {
    draft.enquiryFormEditState = action.payload;
  },

  [EnquiriesActionTypes.SET_INFO_PROPS]: (action: IEnquirySetInfoProps) => {
    draft.infoProps = action.payload;
    // Update default intent checked state
    if (action.payload.defaultIntent) {
      draft.intent = {
        ...EnquiryIntentCheckboxes,
        brochure: false,
        [action.payload.defaultIntent]: true,
      };
    }
  },
});

const reducersActions = getReducerActions(reducers);

const reducer = (state: IEnquiriesState = getInitialState(), action: Action): IEnquiriesState => {
  if (!reducersActions[action.type]) {
    return state;
  }

  try {
    return produce(state, (draft: IEnquiriesState) => reducers(draft)[action.type](action));
  } catch (err) {
    return state;
  }
};

export default reducer;
