import { CohortNames, Experiment } from '@bridebook/toolbox/src/ab-testing/ab-testing';

/**
 * A/B testing main analytics event
 * https://www.notion.so/bridebook/User-participated-in-test-50e7fb7999e64d6aa9ca3c33ad372368
 */
export const getParticipatedInABTestPayload = ({
  abTest: { id, name },
  variant,
}: {
  abTest: Experiment;
  variant: CohortNames;
}) => ({
  event: 'User participated in test',
  testId: id,
  testName: name, // User-friendly name of the test
  testVariant: variant, // Chosen variant of this test
});
